const autoNgTemplateLoaderTemplate1 = require('./nomad-hcl-form.html');

import controller from './nomad-hcl-form.controller.js';

export const NomadHclForm = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,

  bindings: {
    formValues: '=',
    state: '=',
  },
};
