const autoNgTemplateLoaderTemplate1 = require('./registryRepositoriesDatatable.html');

angular.module('portainer.registrymanagement').component('registryRepositoriesDatatable', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'RegistryRepositoriesDatatableController',
  bindings: {
    titleText: '@',
    titleIcon: '@',
    dataset: '<',
    endpointId: '<',
    tableKey: '@',
    orderBy: '@',
    reverseOrder: '<',
    paginationAction: '<',
    loading: '<',
  },
});
