import { AlertCircle } from 'lucide-react';

import { Icon } from '@@/Icon';

export function NomadDeprecationNotice() {
  return (
    <div>
      <div className="vertical-center">
        <Icon icon={AlertCircle} mode="warning" />
        <b>Nomad Deprecation Notice</b>
      </div>
      <div className="text-muted ml-5">
        We want to inform you that, starting from the next minor release, we
        will discontinue support for Nomad within our user interface and API.
        While some users have utilized Nomad, its limited popularity within our
        community has hindered our ability to allocate the required resources
        and maintenance attention. Additionally, supporting Nomad entails
        significant effort and resources, which we aim to reallocate for
        enhancing other crucial features and platforms.
      </div>
    </div>
  );
}
