const autoNgTemplateLoaderTemplate1 = require('./yamlInspector.html');

angular.module('portainer.kubernetes').component('kubernetesYamlInspector', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'KubernetesYamlInspectorController',
  bindings: {
    key: '@',
    data: '<',
    authorised: '<',
    system: '<',
  },
});
